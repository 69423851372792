import React from "react";
import Profile2 from "./zzy2.JPG";

const About = () => {
  return (
    <div id="about-container" className="each-container">
      <div className="profile-container">
        <img src={Profile2} alt="./zzy.JPG" className="profile-img" />
      </div>

      <div className="about-part">
        <div id="about">
          <div className="about-hi">
            Hi, I'm <span className="hi-name">Zhongyue Zhang 张钟月</span>
          </div>
          <br></br>
          <div className="self-intro">
            <div>
              I'm a first-year PhD student in{" "}
              <a href="https://cma.hkust-gz.edu.cn" target="_blank">
                Computational Media and Arts (CMA)
              </a>{" "}
              Thrust at{" "}
              <b>
                The Hong Kong University of Science and Technology (Guangzhou)
              </b>
              . I'm fortunate to be advised by{" "}
              <a href="https://www.mingmingfan.com" target="_blank">
                Prof. Mingming Fan
              </a>{" "}
              and part of the{" "}
              <a href="https://www.mingmingfan.com/lab/" target="_blank">
                Accessible & Pervasive User EXperience (APEX) Group
              </a>
              .
            </div>
            <br></br>
            <div>
              My research interests are human-computer interaction (HCI) and
              accessibility.
            </div>
            <br></br>
            <div>
              I was a Master's student in the Design Engineering (MADE)
              joint degree program at <b>Brown University</b> and{" "}
              <b>Rhode Island School of Design (RISD)</b>. I earned my two
              Bachelor's and a minor with <em>Magna Cum Laude</em> from{" "}
              <b>University of Southern California</b>.
            </div>

            <br></br>
            <div>
              Feel free to reach out to me at:{" "}
              <b>zzhang837@connect.hkust-gz.edu.cn</b>
            </div>
            <br></br>
            <div className="social-media">
              <a
                href="https://www.linkedin.com/in/zhongyue-zhang/"
                class="fa fa-linkedin fa-lg"
                target="_blank"
              ></a>
              <a
                href="https://www.instagram.com/sherley.zhang/"
                class="fa fa-instagram fa-lg"
                target="_blank"
              ></a>
              <a class="fa fa-github fa-lg"></a>
              <a class="fa fa-google fa-lg"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
