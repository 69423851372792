import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/navbar";
import Education from "../components/education/education";
import Awards from "../components/awards/awards";
import About from "../components/about/about";
import News from "../components/news/news";
import Footer from "../components/navbar/footer";
import Projects from "../components/projects/projects";
import Fun from "../components/fun/fun";

const AcademicSec = () => {
  useEffect(() => {
    document.title = "Zhongyue Zhang";
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="page-container">
          <Navbar page="index" />
          <div className="body-part">
            <div>
              <About />
            </div>
            <div>
              <News />
            </div>
            <div>
              <Projects />
            </div>
            <div>
              <Education />
            </div>
            <div>
              <Awards />
            </div>
            <div>
              <Fun />
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default AcademicSec;
