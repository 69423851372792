import React from "react";

const Awards = () => {
  return (
    <div className="each-container" id="awards-container">
      <div className="awards-part">
        <div id="awards">
          <div className="title">Awards</div>
          <ul>
            <li>
              MADE Graduate Studentship (merit-based), Brown University & RISD,
              2022
            </li>
            <li>
              <em>Magna Cum Laude</em> Graduation Honors, University of Southern
              California, 2022
            </li>
            <li>
              Dean's List (all semesters), University of Southern California,
              2018-2022
            </li>
            <li>
              3rd Place of Social Innovation Accelerator Program, Young
              Sustainable Impact (YSI), 2020
            </li>
            <li>
              Bronze Award (3/326) of Food and Beverage Challenge , Foodaily &
              UXPA, 2020 (
              <a href="https://36kr.com/p/858449028782985" target="_blank">
                featured in 36Kr
              </a>
              )
            </li>
            <li>
              <a
                href="https://students.marshall.usc.edu/current-students/international-programs/global-leadership-program-glp"
                target="_blank"
              >
                Global Leadership Program{" "}
              </a>{" "}
              Fellow and Scholarship, USC Marshall School of Business, 2018-2019
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Awards;
