import React from "react";

const News = () => {
  return (
    <div className="each-container" id="news-container">
      <div className="awards-part">
        <div id="news">
          <div className="title">News</div>
          <ul>
            <li>
              <b>Dec 2022</b> Our urban farming design project "Pebble" is{" "}
              <a
                href="https://naturexdesign.tealeaves.com/pebble-the-ritual-of-urban-farming/"
                target="_blank"
              >
                featured
              </a>{" "}
              in NATURE X DESIGN by TEALEAVES 🍃
            </li>
            <li>
              <b>Jul 2022</b> I started my masters in{" "}
              <a href="https://design.engineering.brown.edu" target="_blank">
                Design Engineering
              </a>{" "}
              at Brown and RISD 🎒
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default News;
