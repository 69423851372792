import React from "react";
import { Link } from "react-router-dom";

const Fun = () => {
  return (
    <div className="each-container" id="news-container">
      <div className="awards-part">
        <div id="news">
          <div className="title">Fun</div>
          In spare time, I enjoy{" "}
          <span className="tooltip">
            travelling
            <span className="top">
              🇨🇳🇺🇸🇬🇧🇹🇭🇯🇵🇰🇷🇩🇪🇫🇷🇧🇪🇫🇮🇸🇪🇩🇰🇸🇬🇮🇩🇭🇰🇲🇴
              <i></i>
            </span>
          </span>
          , playing{" "}
          <span className="tooltip">
            instruments{" "}
            <span className="top">
              piano, erhu, guitar, ukulele, electronic keyboard, guqin
              <i></i>
            </span>
          </span>
          , and <Link to="/drawing">drawing things</Link>!
        </div>
      </div>
    </div>
  );
};

export default Fun;
